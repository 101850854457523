import { createRestStore, Mapper } from '@intelliarts/ia-react-template';

import { AccessCard, AccessCardView } from '../models/AccessCard';

export const ACCESS_CARDS_PATH = '/access-cards';

export const accessCardMapper: Mapper<AccessCardView, AccessCard> = {
  toEntity: model => {
    const { fullName, ...accessCard } = model;
    return ({ ...accessCard, userId: Number(accessCard.userId) });
  },
  toModel: entity => ({ ...entity, userId: Number(entity.userId) || null, fullName: '' }),
}

export const AccessCardsStore = createRestStore<AccessCardView, AccessCard>({
  containerDisplayName: 'access-cards-container',
  actionConfig: { resourcePath: ACCESS_CARDS_PATH },
  mapper: accessCardMapper,
});

