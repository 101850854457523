import { StoreActionApi } from 'react-sweet-state';

import {
  createRestStore,
  createGetAction,
  createPutAction,
  getAxiosInstance,
  ResourcesStoreState,
  RestActionType,
  setIsLoaded,
  setIsLoading,
  Mapper,
} from '@intelliarts/ia-react-template';

import { User } from '../models/User';
import { UserView } from '../models/UserView';

export type UserState = ResourcesStoreState<User>;

const EMPTY_OBJECT = {};

export const usersMapper: Mapper<UserView, User> = {
  toEntity: model => {
    const { id, givenName, familyName, fullName, ...restProfile } = model;
    return ({
      ...restProfile,
      id: Number(model.id),
      name: { givenName, familyName, fullName },
    });
  },
  toModel: entity => {
    const { googleId, name, phones, gender, isGoogleUser, ...restProfile } = entity;
    return ({
      ...restProfile,
      givenName: name.givenName,
      familyName: name.familyName,
      fullName: `${name.familyName} ${name.givenName}`,
    });
  },
}

const actionConfig = {
  resourcePath: '/users',
};

const userActions = {
  [RestActionType.GET]: createGetAction<User>({ resourcePath: '/users?withArchived=true&withServiceAccounts=true' }),
  [RestActionType.PUT]: createPutAction<User, UserView>({ resourcePath: '/users' }, usersMapper),
  fetchFromGoogle: createGetAction<User>({
    resourcePath: '/users/google',
  }),
  syncUsersOnDependentServices: () => async ({ dispatch }: StoreActionApi<UserState>) => {
    dispatch(setIsLoading(EMPTY_OBJECT));

    const axiosInstance = getAxiosInstance();
    await axiosInstance.put('/sync');

    return dispatch(setIsLoaded(EMPTY_OBJECT));
  }
};

export const UsersStore = createRestStore<UserView, User>({
  actions: userActions,
  actionConfig,
  containerDisplayName: 'users-container',
  mapper: usersMapper,
});
